<template>
  <el-card class="box-card">
    <div
      style="
        align-items: center;
        display: flex;
        justify-content: end;
        margin: 10px;
      "
    >
      <span style="color: gray; font-size: 13px; margin-right: 10px"
        >选择企业:</span
      >
      <el-select
        v-model="name"
        filterable
        clearable
        placeholder="请选择"
        size="mini"
        style="width: 260px"
        @change="selectChange(name)"
      >
        <el-option
          v-for="item in List"
          :key="item.code"
          :label="item.name"
          :value="item.code"
        ></el-option>
      </el-select>
    </div>
    <div ref="echartBox" style="width: 100%; height: 400px"></div>
  </el-card>
</template>

<script>
import { api } from '/src/api/base';
export const dashboardCompare = api()('dashboard.enterprise.compare.json');
export const enterpriseList = api()('enterprise.simple.list.json');

export default {
  data () {
    return {
      data: null,
      dataLine: null,
      List: [],
      code: '',
      name: '',
    };
  },

  async mounted () {
    await this.getEnterpriseList();
    await this.getData();
    this.setOption();
  },
  methods: {
    async getData () {
      const res = await dashboardCompare({
        enterpriseCode: this.code,
      });
      this.data = res;
      this.dataLine = Object.keys(this.data).map((it) => it);
    },
    async getEnterpriseList () {
      const res = await enterpriseList({
        pageNumber: 1,
        pageSize: 10000,
      });
      this.List = res.list;
      this.code = this.List[0].code;
      this.name = this.List[0].name;
      console.log(this.code);
    },
    async selectChange (name) {
      this.code = name;
      await this.getData();
      setTimeout(() => {
        this.setOption();
      }, 100);
    },
    setOption () {
      var myChart = window.echarts.init(this.$refs.echartBox);
      let serverData = [];
      Object.keys(this.data).forEach((it) => {
        serverData.push({
          name: it,
          type: 'line',
          smooth: true,
          symbolSize: 8,
          symbol: 'circle',
          // itemStyle: {
          //   color: this.colorList[i],
          // },
          data: this.data[it].map((it) => it.total),
        });
      });
      var option = {
        title: {
          text: '企业同期业务数据对比',
          left: '12%',
          top: '3%',
          textStyle: {
            color: '#000000',
            fontWeight: 'bolder',
            fontSize: 17,
          },
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          orient: 'horizontal',
          right: '10%',
          data: this.dataLine,
          lineStyle: {
            width: 2,
            height: 'solid',
          },
        },
        xAxis: {
          name: '日期',
          nameTextStyle: {
            color: '#595959',
          },
          axisTick: {
            alignWithLabel: true,
          },
          data: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月',
          ],
        },
        yAxis: {
          nameTextStyle: {
            align: 'center',
            color: '#595959',
          },
        },
        color: ['#3aa3fc', '#91fc15', '#7f15fc', '#5B8FF9', '#ae570d'],
        series: serverData,
      };
      myChart.setOption(option, true);
    },
  },
};
</script>

<style>
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #73a0fa;
  border-color: #73a0fa;
}
.el-radio-button__inner:hover {
  color: #73a0fa;
}
</style>
